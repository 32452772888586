import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { Observable, Subject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {
    CalculatePriceFullResponse,
    CalculatePriceLiteResponse,
    ConditionType,
    OfferItem,
    OfferPriceCalculationParams,
    OfferPriceCalculationResponse,
    PriceParams,
    PriceSummary,
    voucherConditionTypes,
} from './price-summary.model';

@Injectable({
    providedIn: 'root',
})
export class PriceSummaryDataService {
    public isPriceSummaryLoading$: Subject<{ value: boolean }> = new Subject<{ value: boolean }>();

    public hasErrors$: Subject<{ value: boolean }> = new Subject<{ value: boolean }>();

    private lastPriceSummary: PriceSummary;

    constructor(private httpService: HttpService) {}

    private round(x: number): number {
        return x ? Number(x.toFixed(2)) : 0;
    }

    private summConditions(items: OfferItem[], ...types: string[]) {
        return this.round(
            items
                .flatMap((i) => i.conditions)
                .filter((c) => types.includes(c.type))
                .map(({ condValue }) => condValue || 0)
                .reduce((total, value) => total + value, 0)
        );
    }

    public fetchOfferPrice(payload: OfferPriceCalculationParams): Observable<PriceSummary> {
        this.onStart();
        const url = `${environment.http.quotation}price/calculate`;
        const result: Observable<PriceSummary> = this.httpService
            .post<OfferPriceCalculationResponse>(url, payload)
            .pipe(
                map((resp) => resp.offer),
                map((offer) => {
                    const items = offer.items;
                    const netPrice = this.round(offer.netTotal);
                    const vatRow = offer.summaryRows.find((sr) => sr.conditionKeyType === ConditionType.vat);
                    const vat = this.round(vatRow?.calculatedValue);
                    const vatRate = this.round(vatRow?.conditionKeyValue);
                    const sum = netPrice + vat;
                    const totalAdvantage = this.round(
                        offer.summaryRows.find((sr) => sr.conditionKeyType === ConditionType.standard)?.calculatedValue
                    );
                    const totalGross = this.round(offer.grossTotal);
                    const totalPrice = totalGross + totalAdvantage;
                    const extraBonus = this.round(this.summConditions(items, ConditionType.extraBonus));
                    const voucherValue = this.round(this.summConditions(items, ...voucherConditionTypes));

                    return {
                        isFull: true,
                        categories: offer.items.map((item) => ({
                            itemNumber: item.itemNumber.toString(),
                            material: item.material,
                            category: item.itemCategory,
                        })),
                        grossPrices: offer.items.map((item) => ({
                            material: item.material,
                            gross: item.grossPrice,
                        })),
                        prices: {
                            extraBonus,
                            netPrice,
                            sum,
                            totalAdvantage,
                            totalGross,
                            totalPrice,
                            vat,
                            vatRate,
                            voucherValue,
                        },
                    };
                })
            );
        return this.onEnd(result);
    }

    public fetchPriceSummary(params: PriceParams, full = true): Observable<PriceSummary> {
        this.onStart();
        let result: Observable<PriceSummary>;
        if (full) {
            result = this.httpService
                .post<CalculatePriceFullResponse>(`${environment.http.baseUrl}price/calculate`, params)
                .pipe(map((res) => ({ ...res, isFull: true })));
        } else {
            const url = `${environment.http.quotation}price/calculate/lite`;
            const { salesOrg, materials, discounts, priceAdvantageEnabled } = params;
            result = this.httpService
                .post<CalculatePriceLiteResponse>(url, {
                    salesOrg,
                    materials,
                    discounts,
                    priceAdvantageEnabled,
                })
                .pipe(map((res) => ({ ...res, isFull: false })));
        }

        return this.onEnd(result);
    }

    private onStart() {
        this.isPriceSummaryLoading$.next({ value: true });
    }

    private onEnd(result: Observable<PriceSummary>) {
        return result.pipe(
            tap((result) => (this.lastPriceSummary = result)),
            finalize(() => {
                this.isPriceSummaryLoading$.next({ value: false });
            })
        );
    }

    public getLastPriceSummary() {
        return this.lastPriceSummary;
    }
}
