import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { LoadingOverlayComponent } from '../_shared/components/loading-overlay/loading-overlay.component';
import { PriceSummaryDataService } from '../_shared/components/price-summary/price-summary.data.service';
import { PriceSummaryHelperService } from '../_shared/components/price-summary/price-summary.helper.service';
import { ProductListService } from '../_shared/components/product-list/product-list.service';
import { PermissionService } from '../_shared/services/permission.service';
import { Product, ProductGroup, ProductsService } from '../_shared/services/products.service';
import { PrintType, QuoteService } from '../_shared/services/quote.service';
import { SelectedCustomerService } from '../_shared/services/selected-customer.service';
import { SnackBarService } from '../_shared/services/snack-bar.service';
import { SummaryDataService } from './summary.data.service';
import { AppService } from '../_shared/services/app.service';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private dialogRef: MatDialogRef<LoadingOverlayComponent>;

    private documentId: string;
    private latestRevision: string;
    private quickRef: string;
    private title: string;
    private productList: Product[];
    public selectedCustomerId: string;
    public navigationParams: Params;
    public dataSource: any;
    public isLoading = true;
    public voucher: string = null;
    public callbackUrl: string;
    public secondaryCallbackUrl: string;
    public externalApp: boolean;
    public availablePrintTypes: string[];
    public printType: PrintType;
    public quotationRequestId: string;
    public salesforceIdControl: FormControl<string>;
    public planning: boolean;
    public salesforceIdForPlanning;
    public primaryScheme: string;

    constructor(
        private appService: AppService,
        private productsService: ProductsService,
        private router: Router,
        private priceSummaryService: PriceSummaryHelperService,
        private priceSummaryDataService: PriceSummaryDataService,
        private snackBarService: SnackBarService,
        private translateService: TranslateService,
        private activatedRoute: ActivatedRoute,
        private selectedCustomerService: SelectedCustomerService,
        private quoteService: QuoteService,
        private dataService: SummaryDataService,
        private productListService: ProductListService,
        public permissionService: PermissionService,
        private dialog: MatDialog
    ) {}

    public ngOnInit() {
        const {
            documentId,
            latestRevision,
            quickRef,
            title,
            callbackUrl,
            secondaryCallbackUrl,
            salesforceId,
            quotationRequestId,
            planning,
        } = this.activatedRoute.snapshot.queryParams;

        this.appService.salesOrg$
            .pipe(
                takeUntil(this.ngUnsubscribe),
                concatMap((salesOrg) => this.dataService.getAvailablePrintTypes(salesOrg))
            )
            .subscribe((printTypes) => {
                this.availablePrintTypes = printTypes;
                // default should be 'L' if available, otherwise use 'M' or 'S'
                this.printType = <PrintType>['L', 'M', 'S'].find((t) => printTypes.includes(t));
            });
        this.quickRef = quickRef;
        this.documentId = documentId;
        this.latestRevision = latestRevision;
        this.title = title;
        this.callbackUrl = callbackUrl;
        this.secondaryCallbackUrl = secondaryCallbackUrl;
        if (salesforceId) {
            if (salesforceId === 'ask' && this.permissionService.isUserAnEmployee) {
                this.salesforceIdControl = new FormControl<string>('', Validators.required);
                this.salesforceIdControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
                    this.quoteService.updateQuoteProperty('salesforceId', value);
                });
            } else {
                this.quoteService.updateQuoteProperty('salesforceId', salesforceId);
            }
        }
        if (quotationRequestId) {
            this.quotationRequestId = quotationRequestId;
        }
        this.planning = Boolean(planning);
        if (this.planning) {
            this.salesforceIdForPlanning = salesforceId;
        }

        this.navigationParams = {
            quickRef: this.quickRef,
            latestRevision: this.latestRevision,
            documentId: this.documentId,
            title: this.title,
            ...(quotationRequestId && { quotationRequestId }),
            ...(this.planning && { planning: true, salesforceId: this.salesforceIdForPlanning }),
        };

        this.quoteService
            .fetchQuote({ documentId, latestRevision })
            .pipe(take(1), takeUntil(this.ngUnsubscribe))
            .subscribe((quote) => {
                this.externalApp = !!quote.externalApp;
                this.primaryScheme = quote.schemePdfs?.primary?.split(';')[0];
                if (quote.quote.status.toLowerCase() !== 'new') {
                    this.closeDialog();
                    this.router.navigate(['next-steps'], {
                        queryParams: {
                            documentId: this.documentId,
                            latestRevision: this.latestRevision,
                            sapDocumentId: quote.quote.salesDocumentNumber,
                            printType: quote.printType,
                            ...(this.planning && { planning: true, salesforceId: this.salesforceIdForPlanning }),
                        },
                    });
                }

                this.isLoading = false;
                if (this.externalApp && this.salesforceIdControl && quote.salesforceId) {
                    this.salesforceIdControl.setValue(quote.salesforceId);
                }
                const appTitle = this.quoteService.hasConfiguration(quote.quote) ? '' : quote.externalApp;
                this.appService.appTitle$.next(appTitle);
            });
        this.subscribeToProductList$();
        this.subscribeToSelectedCustomer$();
    }

    public get hasConfiguration() {
        return this.quoteService.hasConfiguration();
    }

    public priceSummaryIsLoading() {
        return this.priceSummaryDataService.isPriceSummaryLoading$;
    }

    public priceSummaryHasErrors() {
        return this.priceSummaryDataService.hasErrors$;
    }

    public updateVoucherCode(event: string) {
        this.voucher = event;
    }

    public closeOffer() {
        // if currently logged in user is a customer, just use the viCompanyId of the currently logged in user
        const isCustomerLoggedIn =
            this.permissionService.userInfo$.value && this.permissionService.userInfo$.value.role === 'customer';
        let customerIdForClose = 'no-customer-selected';
        if (this.selectedCustomerId) {
            customerIdForClose = this.selectedCustomerId;
        } else if (isCustomerLoggedIn) {
            // use the viCompanyId
            customerIdForClose = this.permissionService.userInfo$.value.viCompanyId;
        }
        const salesforceId = this.quoteService.getCurrentQuote().salesforceId;

        this.isLoading = true;

        this.quoteService
            .updateQuoteProperty('printType', this.printType)
            .pipe(concatMap(() => this.closeQuote(customerIdForClose, salesforceId)))
            .subscribe(
                (res: any) => {
                    const sapId = res.salesDocumentNumber;

                    this.onSuccess('SUMMARY.SNACK_BAR.CLOSE_OFFER.SUCCESS');
                    this.router.navigate(['next-steps'], {
                        queryParams: {
                            documentId: this.documentId,
                            latestRevision: this.latestRevision,
                            sapDocumentId: sapId,
                            printType: this.printType,
                            callbackUrl: this.secondaryCallbackUrl,
                            ...(this.planning && { planning: true, salesforceId }),
                        },
                    });
                },
                () => this.onError('SUMMARY.SNACK_BAR.CLOSE_OFFER.ERROR')
            );
    }

    public navigateBackToConfiguration() {
        this.router.navigate(['configuration'], { queryParams: this.navigationParams });
    }

    private closeQuote(customerNumber: string, salesforceId: string) {
        const groupedProducts: ProductGroup[] = this.productsService.getProductListViewModel(this.productList, false);
        if (this.appService.useOfferService) {
            return this.dataService.createOffer(
                customerNumber,
                this.quotationRequestId,
                this.voucher || '',
                groupedProducts,
                this.priceSummaryDataService.getLastPriceSummary()
            );
        } else {
            const payload = this.priceSummaryService.getParamForQuoteClose(groupedProducts, customerNumber);
            if (salesforceId) {
                payload.salesforceId = salesforceId;
            }
            if (this.quotationRequestId) {
                payload.quotationRequestId = this.quotationRequestId;
            }

            if (this.voucher) {
                payload.voucherCode = this.voucher;
            }
            const params = { documentId: this.documentId, latestRevision: this.latestRevision };
            return this.dataService.closeQuoteInSap(params, payload);
        }
    }

    private subscribeToSelectedCustomer$() {
        this.selectedCustomerService.selectedCustomer$
            .pipe(takeUntil(this.ngUnsubscribe), filter(Boolean))
            .subscribe((customer: { number: string }) => {
                this.selectedCustomerId = customer.number;
            });
    }

    private subscribeToProductList$() {
        this.openDialog();
        this.productListService.productList$.pipe(takeUntil(this.ngUnsubscribe), filter(Boolean)).subscribe(
            (products: Product[]) => {
                this.productList = products;
                const productList = this.productsService
                    .getProductListViewModel(products, true)
                    .map((product: Product) => {
                        const formGroup = product.material
                            ? // add edit form controls, quantity only for external products
                              new UntypedFormGroup({
                                  optional: new FormControl<boolean>({
                                      value: product.optional,
                                      disabled: !product.canBeOptional,
                                  }),
                                  ...(product.category === 'EXTERNAL' && {
                                      quantity: new FormControl<number>(product.quantity),
                                  }),
                              })
                            : undefined;
                        return {
                            ...product,
                            formGroup,
                        };
                    });

                this.dataSource = new MatTableDataSource(productList);
                this.closeDialog();
            },
            () => {
                this.onError('CONFIGURATION.SNACK_BAR.FETCH_CONFIGURATION.ERROR');
                this.closeDialog();
            }
        );
    }

    private onError(translationKey: string) {
        this.isLoading = false;
        this.snackBarService.openSnackBar({
            message: this.translateService.instant(translationKey),
            isFailure: true,
        });
    }

    private onSuccess(translationKey: string) {
        this.isLoading = false;
        this.snackBarService.openSnackBar({
            message: this.translateService.instant(translationKey),
        });
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private openDialog(): void {
        if (!this.dialogRef) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.width = '250px';
            dialogConfig.scrollStrategy = new NoopScrollStrategy();
            this.dialogRef = this.dialog.open(LoadingOverlayComponent, dialogConfig);
        }
    }

    private closeDialog(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
        }
    }
}
