import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppService } from '../_shared/services/app.service';
import { SelectedCustomerService } from '../_shared/services/selected-customer.service';
import { PermissionService } from '../_shared/services/permission.service';
import { concatMap, filter, map, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PrintType } from '../_shared/services/quote.service';

@Component({
    selector: 'app-next-steps',
    templateUrl: './next-steps.component.html',
    styleUrls: ['./next-steps.component.scss'],
})
export class NextStepsComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject<void>();
    constructor(
        private activatedRoute: ActivatedRoute,
        private selectedCustomerService: SelectedCustomerService,
        private permissionService: PermissionService,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer,
        public appService: AppService
    ) {
        matIconRegistry.addSvgIcon(
            'coin-stack',
            domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/coin-stack.svg')
        );
    }

    public sapDocumentId: string;
    public documentId: string;
    public latestRevision: string;
    public shopUrl: string;
    public foerderProfiUrl: string;
    public serviceConfiguratorUrl: string;
    public printType: PrintType;
    public callbackUrl: string;
    public planning: boolean;
    public salesforceId: string;

    public ngOnInit() {
        this.permissionService.userInfo$
            .pipe(
                takeUntil(this.unsubscribe$),
                map(() => this.permissionService.isUserAnEmployee),
                filter(Boolean),
                concatMap(() => this.selectedCustomerService.selectedCustomer$),
                tap((customer) => {
                    this.setShopUrl(customer?.number);
                })
            )
            .subscribe();
        const { documentId, latestRevision, sapDocumentId, printType, callbackUrl, planning, salesforceId } =
            this.activatedRoute.snapshot.queryParams;
        this.documentId = documentId;
        this.latestRevision = latestRevision;
        this.sapDocumentId = sapDocumentId;
        this.foerderProfiUrl = environment.http.foerderProfiUrl;
        this.serviceConfiguratorUrl = `${environment.http.serviceConfiguratorBaseUrl}/configuration/new?offerNumber=${this.sapDocumentId}`;
        this.printType = printType || 'L';
        this.callbackUrl = callbackUrl;
        this.setShopUrl();
        this.planning = Boolean(planning);
        this.salesforceId = salesforceId;
    }

    private setShopUrl(customerNumber?: string) {
        this.shopUrl = `${environment.http.shopUrl}${this.sapDocumentId}${
            customerNumber ? `&sapCustomerNumber=${customerNumber}` : ''
        }`;
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
