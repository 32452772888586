import { appVersion } from './app-version';
import { AppConfiguration } from './AppConfiguration';
import { supportedLanguages } from './supported-languages';

export const environment: AppConfiguration = {
    production: true,
    instana: true,
    serviceWorkerEnabled: false,
    version: appVersion,
    env: 'production',
    app: {
        defaultLanguage: 'de-DE',
        supportedLanguages,
        priceAdvantageNotRelevantSalesOrgs: ['0600', '6200'],
        productCategoryGroupNameMapSalesOrg: ['6200'],
    },
    appInsights: {
        enabled: true,
        connectionString:
            'InstrumentationKey=49372b9a-f246-4022-bf13-09d7f1af652f;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/',
    },
    quest: {
        context: 'quest',
        dropoutGroup: 'Dropout',
        project: 'QuoteAssist',
        modelSelectionVariable: 'Anonymous.ProductGroup',
        preselectionVariable: 'Miscellaneous.PreSelection',
    },
    configit: {
        url: 'https://api.viessmann.com/backend/package-packer/v2',
        languages: ['de_DE'],
        salesAreaName: 'Germany',
        plant: '0500',
        usage: '5',
    },
    offline: {
        enabled: false,
        keyword: 'offline=1',
        baseUrl: 'https://quote-assist.viessmann.com/assets/offline/',
    },
    http: {
        shopUrl: 'https://shop.viessmann.com/?shopApiContext=qa-import&offerNumber=',
        foerderProfiUrl:
            'https://foerder-profi.de/?utm_source=angebots_assistent&utm_medium=referral&utm_campaign=kostenfreie_pruefung',
        baseUrl: 'https://api.viessmann.com/backend/package-packer/v2/',
        baseClientUrl: 'https://quote-assist.viessmann.com/',
        clientUrl: 'https://quote-assist.viessmann.com/overview',
        viBooksBaseUrl: 'https://vibooks.viessmann.com',
        serviceConfiguratorBaseUrl: 'https://service-configurator.viessmann.com',
        users: 'https://api.viessmann.com/users/v1/users/me?sections=identity&sections=sales&sections=roles',
        translations:
            'https://api.viessmann.com/translations/v2/phraseapp-proxy/projects/f904ba58454342e5ede6967b374ef7f9/locales/',
        dswTranslations:
            'https://api.viessmann.com/translations/v2/phraseapp-proxy/projects/ec38b083a23c58c604a49d7f443dd7f2/locales/',
        statusPage: 'https://api.viessmann.com/status/v1/apps/rhwny5sryyyt/summary',
        configuration: 'https://api.viessmann.com/backend/configuration/v1/',
        quotation: 'https://api.viessmann.com/quotation/v1/',
        viGuidePlanningUrl: 'https://viguide-planning.viessmann.com/project/',
        faq: {
            '0500': 'https://partnerportal.viessmann.com/de/de/support/faq/quote-assist.html',
            default: 'https://support.viessmann.com/?page=1&pageSize=8',
        },
        companyUrl: 'https://api.viessmann.com/company/v3/companies/',
        pim: 'https://api.viessmann.com/backend/pim/v1/',
    },
    globalHeader: {
        baseUrl: 'https://api.viessmann.com',
        accountAppUrl: 'https://account.viessmann.com',
    },
    saml: {
        appId: 'quote-assist',
        baseUrl: 'https://api.viessmann.com',
    },
    file: {
        uploadUrl: 'upload',
        downloadUrl: 'download',
    },
    feature: {
        salesorgSwitcher: false,
    },
    requireSalesOrg: true,
    vkOrgsAuthorziedToUseTemplates: ['0500'],
};
