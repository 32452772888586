<app-card [isFooter]="true" [isHeader]="true">
    <h2 header class="title">{{ 'NEXT_STEPS.LABEL.SELECT_DOCUMENTS' | translate }}</h2>
    <div body>
        <div fxLayout="row">
            <div fxLayout="column" fxFlex.gt-sm="50" fxFlex.lt-md="100" class="checkbox-container">
                <!-- Select All -->
                <div>
                    <mat-checkbox
                        color="primary"
                        class="select-all"
                        [checked]="isAllSelected()"
                        [indeterminate]="isIndeterminate()"
                        (change)="toggleAll()"
                        [matTooltip]="'NEXT_STEPS.TOOLTIP.SELECT' | translate"
                        >{{ 'NEXT_STEPS.LABEL.SELECT_ALL' | translate }}</mat-checkbox
                    >
                </div>

                <!-- Quote PDF -->
                <ng-container *ngIf="config.includes('QUOTE')">
                    <div fxLayoutAlign="space-between center" *ngFor="let quotePdfDownload of quotePdfDownloads">
                        <mat-checkbox
                            color="primary"
                            class="checked"
                            [checked]="true"
                            [disabled]="true"
                            [matTooltip]="'NEXT_STEPS.TOOLTIP.SELECT' | translate"
                            >{{ 'NEXT_STEPS.LABEL.PDF.' + quotePdfDownload.type | translate }} PDF</mat-checkbox
                        >
                        <div>
                            <app-document-actions
                                [model]="quotePdfDownload"
                                [download]="true"
                                [inline]="true"
                            ></app-document-actions>
                        </div>
                    </div>
                </ng-container>

                <!-- Schemes -->
                <div [formGroup]="schemeForm" fxLayout="column" class="schemes" *ngIf="isSchemesAvailable">
                    <div>
                        <mat-checkbox
                            [checked]="isAllSchemesSelected()"
                            [indeterminate]="isSchemesIndeterminate()"
                            (change)="toggleAllSchemes()"
                            [matTooltip]="'NEXT_STEPS.TOOLTIP.SELECT' | translate"
                            >{{ 'NEXT_STEPS.LABEL.SCHEMES' | translate }}</mat-checkbox
                        >
                    </div>
                    <div class="indented" fxLayout="column">
                        <div fxLayoutAlign="space-between center" *ngFor="let scheme of schemeIds?.primary">
                            <mat-checkbox
                                color="primary"
                                [formControlName]="scheme"
                                [matTooltip]="'NEXT_STEPS.TOOLTIP.SELECT' | translate"
                                >{{ 'NEXT_STEPS.LABEL.SCHEME' | translate }} {{ scheme }}
                            </mat-checkbox>

                            <div>
                                <app-document-actions
                                    [model]="schemeDownloadUrl(scheme)"
                                    [download]="true"
                                    [inline]="true"
                                ></app-document-actions>
                            </div>
                        </div>

                        <ng-container *ngIf="schemeIds?.secondary?.length">
                            <div class="label">{{ 'NEXT_STEPS.LABEL.SECONDARY_SCHEMES' | translate }}</div>

                            <div fxLayoutAlign="space-between center" *ngFor="let scheme of schemeIds.secondary">
                                <mat-checkbox
                                    color="primary"
                                    [formControlName]="scheme"
                                    [matTooltip]="'NEXT_STEPS.TOOLTIP.SELECT' | translate"
                                    >{{ 'NEXT_STEPS.LABEL.SCHEME' | translate }} {{ scheme }}
                                </mat-checkbox>
                                <div>
                                    <app-document-actions
                                        [model]="schemeDownloadUrl(scheme)"
                                        [download]="true"
                                        [inline]="true"
                                    ></app-document-actions>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <!-- Energy Label -->
                <div fxLayoutAlign="space-between center" *ngIf="config.includes('ENERGY_LABEL') && hasConfiguration">
                    <mat-checkbox
                        color="primary"
                        [disabled]="disableEnergyLabelCheckbox"
                        [(ngModel)]="energyLabelSelected"
                        [matTooltip]="'NEXT_STEPS.TOOLTIP.SELECT' | translate"
                        >{{ 'NEXT_STEPS.LABEL.ENERGY_LABEL' | translate }}
                        <vi-quest-hint
                            *ngIf="energyLabelNotRelevant"
                            [model]="{
                                text: 'NEXT_STEPS.ENERGY_LABEL.TOOLTIP.DISABLED' | translate,
                                important: false
                            }"
                        >
                        </vi-quest-hint>
                    </mat-checkbox>

                    <div>
                        <app-document-actions
                            [model]="energyLabelPdfDownload"
                            [download]="true"
                            [inline]="true"
                        ></app-document-actions>
                    </div>
                </div>

                <!-- Export Formats -->
                <div fxLayoutAlign="space-between center" *ngFor="let exportFormat of availableExportFormats">
                    <mat-checkbox
                        color="primary"
                        [checked]="isExportFormatSelected(exportFormat)"
                        (change)="onExportFormatSelected($event, exportFormat)"
                        [matTooltip]="'NEXT_STEPS.TOOLTIP.SELECT' | translate"
                        >{{ exportFormat.replace('_', ' ') }}</mat-checkbox
                    >
                    <div>
                        <app-document-actions
                            [model]="exportFormatDownloadUrl(exportFormat)"
                            [download]="true"
                        ></app-document-actions>
                    </div>
                </div>

                <!-- Upload Attachments -->
                <div [formGroup]="attachmentForm">
                    <div fxLayoutAlign="space-between center" *ngFor="let attachment of uploadAttachments">
                        <mat-checkbox color="primary" [formControlName]="attachment.id">{{
                            attachment.filename
                        }}</mat-checkbox>
                        <app-document-actions
                            [model]="setDownloadUrl(attachment.documentUri)"
                            [download]="true"
                            [inline]="true"
                        ></app-document-actions>
                    </div>
                </div>

                <!-- Additional Offer Attachments -->
                <div [formGroup]="additionalAttachmentsForm">
                    <div fxLayoutAlign="space-between center" *ngFor="let attachment of additionalAttachments">
                        <mat-checkbox color="primary" [formControlName]="attachment.id">{{
                            attachment.name
                        }}</mat-checkbox>
                        <app-document-actions
                            [model]="attachment.url"
                            [download]="true"
                            [inline]="false"
                        ></app-document-actions>
                    </div>
                </div>

                <!-- ViBooks -->
                <div fxLayoutAlign="space-between center">
                    <div class="label indented">{{ 'NEXT_STEPS.LABEL.VIBOOKS' | translate }}</div>
                    <div>
                        <app-document-actions [inline]="true" [model]="getViBooksUrl()"></app-document-actions>
                    </div>
                </div>

                <!-- Upload -->
                <div class="upload-container">
                    <div class="upload-dropzone">
                        <input
                            type="file"
                            multiple
                            ngFileSelect
                            [options]="options"
                            [uploadInput]="startUpload"
                            (uploadOutput)="onUpload($event)"
                            [attr.accept]="options.allowedContentTypes.join()"
                            #input
                        />
                        <div class="dropzone-interface">
                            <mat-icon>description</mat-icon>
                            {{ 'NEXT_STEPS.UPLOAD.DROPZONE' | translate }}
                        </div>
                    </div>
                    <div class="upload-storage" *ngIf="atLeastOneFileUploaded()">
                        <div
                            class="upload-storage-item"
                            [class.queued]="uploadFile.queued"
                            [class.uploading]="uploadFile.uploading"
                            [class.uploaded]="uploadFile.control?.value"
                            [class.dragging]="dragging"
                            [class.error]="uploadFile.control.hasError('max') || uploadFile.control.hasError('upload')"
                            *ngFor="let uploadFile of uploadFileControls"
                        >
                            <div class="upload-storage-progress">
                                <div class="progress-details">
                                    <div class="progress-icon">
                                        <mat-spinner [diameter]="24"></mat-spinner>
                                    </div>
                                    <div class="progress-content">
                                        {{ 'NEXT_STEPS.UPLOAD.UPLOADING' | translate : uploadFile.uploading }}
                                    </div>
                                </div>
                                <div class="progress-actions">
                                    <button mat-icon-button (click)="cancel(uploadFile)">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="upload-storage-queue" *ngIf="uploadFile.control?.valid">
                                <div class="queue-icon">
                                    <mat-spinner [diameter]="24"></mat-spinner>
                                </div>
                                <div class="queue-content">
                                    {{ 'NEXT_STEPS.UPLOAD.QUEUE' | translate }}
                                </div>
                            </div>
                            <div class="upload-storage-file">
                                <div class="file-details">
                                    <div class="file-icon" [class.success]="uploadFile.control?.valid">
                                        <mat-icon *ngIf="uploadFile.control?.valid && !uploadFile.deleting"
                                            >check_circle</mat-icon
                                        >
                                        <mat-icon
                                            *ngIf="!uploadFile.control?.valid && !uploadFile.deleting"
                                            color="error"
                                            >cancel</mat-icon
                                        >
                                        <mat-spinner diameter="24" *ngIf="uploadFile.deleting"></mat-spinner>
                                    </div>
                                    <div class="file-name">
                                        <span *ngIf="uploadFile.deleting">
                                            {{ 'NEXT_STEPS.UPLOAD.DELETING' | translate : uploadFile.control?.value }}
                                        </span>
                                        <span *ngIf="!uploadFile.deleting">
                                            {{ 'NEXT_STEPS.UPLOAD.UPLOADED' | translate : uploadFile.control?.value }}
                                        </span>
                                    </div>
                                </div>
                                <!-- only for attachments from backend, not for currently uploading files (in that case nativeFile is set) -->
                                <ng-container *ngIf="uploadFile.file && !uploadFile.file.nativeFile">
                                    <div class="file-actions">
                                        <button
                                            mat-icon-button
                                            (click)="deleteAttachment(uploadFile)"
                                            [disabled]="uploadInProgress()"
                                            [matTooltip]="'NEXT_STEPS.UPLOAD.TOOLTIP.DELETE' | translate"
                                        >
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf="uploadFile.control.hasError('max')" class="upload-storage-error">
                                {{ 'NEXT_STEPS.UPLOAD.ERROR.MAX' | translate : uploadFile.control?.errors?.max }}
                            </div>
                            <div *ngIf="uploadFile.control.hasError('upload')" class="upload-storage-error">
                                {{ 'NEXT_STEPS.UPLOAD.ERROR.UPLOAD' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div footer fxLayoutAlign="start start">
        <button mat-button color="primary" [disabled]="!canSendEmail" (click)="sendMail()">
            <mat-icon>mail_outline</mat-icon>
            {{ 'NEXT_STEPS.BUTTON.SEND_MAIL_SELECTION' | translate }}
        </button>
        <a mat-button color="primary" [href]="downloadSelectionUrl()">
            <mat-icon>archive</mat-icon>
            {{ 'NEXT_STEPS.BUTTON.DOWNLOAD_SELECTION' | translate }}
        </a>
    </div>
</app-card>
