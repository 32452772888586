<app-main-content content>
    <app-loader main *ngIf="loading" [isLoading]="loading" [message]="'PLANNING.LOADER_TEXT' | translate"></app-loader>
    <div main *ngIf="project">
        <div class="quote-container">
            <div class="planning-quotes">
                <div class="planning-variants" *ngFor="let variant of project.variants">
                    <h1 class="variant-title">
                        {{ 'PLANNING.PROJECT.TITLE' | translate }}: <span>{{ project.title }}</span
                        ><span *ngIf="variant.variantName"> ({{ variant.variantName }})</span>
                    </h1>
                    <div class="variant-quotes">
                        <mat-card appearance="outlined" *ngFor="let quote of variant.quotes">
                            <mat-card-content>
                                <div class="img-container">
                                    <img [src]="getImageByType(quote)" />
                                </div>
                                <div class="text-container">
                                    <h2 class="planning-title">
                                        {{ 'PLANNING.TYPE.' + quote.planningType + '.TITLE' | translate }}
                                    </h2>
                                    <p class="planning-subtitle subtext">
                                        {{ 'PLANNING.STATUS.LABEL' | translate }}:
                                        {{ 'PLANNING.STATUS.' + quote.status.toUpperCase() | translate }}
                                        <ng-container *ngIf="isClosed(quote)"
                                            >({{ quote.salesDocumentNumber }})</ng-container
                                        >
                                    </p>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="end">
                                    <a
                                        mat-flat-button
                                        color="primary"
                                        [routerLink]="[
                                            '../..',
                                            supportsConfiguration(quote) ? 'configuration' : 'summary'
                                        ]"
                                        [queryParams]="queryParams(quote)"
                                        *ngIf="!isClosed(quote)"
                                    >
                                        {{ 'PLANNING.ACTIONS.PROVIDE_QUOTE' | translate }}
                                    </a>
                                    <a
                                        mat-flat-button
                                        color="primary"
                                        [routerLink]="['../..', 'next-steps']"
                                        [queryParams]="queryParams(quote)"
                                        *ngIf="isClosed(quote)"
                                        >{{ 'PLANNING.ACTIONS.OPEN_QUOTE' | translate }}</a
                                    >
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
            <div fxLayout="row">
                <a mat-flat-button [href]="viGuidePlanningUrl">{{ 'PLANNING.BACK_TO_VG_PLANNING' | translate }}</a>
            </div>
        </div>
    </div>
</app-main-content>
