import { Locale } from '../app/_shared/services/app.service';

export const supportedLanguages: Locale[] = [
    { locale: 'de-DE', flag: 'de', salesOrg: '0500' },
    { locale: 'en-DE', flag: 'de', salesOrg: '0500' },
    { locale: 'de-AT', flag: 'at', salesOrg: '5000' },
    { locale: 'fr-BE', flag: 'be', salesOrg: '6000' },
    { locale: 'nl-BE', flag: 'be', salesOrg: '6000' },
    { locale: 'fr-FR', flag: 'fr', salesOrg: '0600' },
    { locale: 'de-CH', flag: 'ch', salesOrg: '6200' },
    { locale: 'fr-CH', flag: 'ch', salesOrg: '6200' },
    { locale: 'it-CH', flag: 'ch', salesOrg: '6200' },
    { locale: 'it-IT', flag: 'it', salesOrg: '7300' },
    { locale: 'sk-SK', flag: 'sk', salesOrg: '7900' },
    { locale: 'pl-PL', flag: 'pl', salesOrg: '9500' },
    { locale: 'cs-CZ', flag: 'cz', salesOrg: '9700' },
    { locale: 'de-LU', flag: 'lu', salesOrg: '6100' },
    { locale: 'fr-LU', flag: 'lu', salesOrg: '6100' },
    { locale: 'es-ES', flag: 'es', salesOrg: '7100' },
    { locale: 'sl-SI', flag: 'si', salesOrg: '7500' },
    { locale: 'tr-TR', flag: 'tr', salesOrg: '8000' },
    { locale: 'de-NL', flag: 'nl', salesOrg: '5900' },
    { locale: 'nl-NL', flag: 'nl', salesOrg: '5900' },
];
